<template>
  <b-container class="body-container">

    <loader message="Loading Resignation"
            :show="!resignation" />

    <div v-if="resignation">
      <h1>Resignation for {{ resignation.fullName }}</h1>

      <div class="mt-2 mb-5">
        <h2>Status</h2>

        <div v-if="resignation.confirmationReceivedDate">

          <b-jumbotron>

            <p>Congratulations! Your resignation has been completed.</p>

            <b-button :disabled="downloadInProgress"
                      variant="primary"
                      @click="downloadConfirmationCertificate">
              <font-awesome-icon icon="certificate" />
              Download Certificate
            </b-button>

            <loader message="Please wait..."
                    :show="downloadInProgress" />

          </b-jumbotron>
        </div>

        <div v-else-if="resignation.submittedToMembershipDate">
          <p>Your resignation has been sent to Kirton McConkie. We are now waiting for confirmation that they have removed your name from the church's records. </p>
          <p>Once we hear back from Kirton McConkie, we'll update your resignation status and notify you.</p>
          <p>This process can take up to 90 days. We don't know why it takes so long, Kirton McConkie refuses to tell us, and we are working on making it faster. Please understand
            that it is out of our control, but we are doing our best.</p>
          <p>If we do not hear back from Kirton McConkie within <b>30 days</b> we will automatically re-send your resignation.</p>
        </div>

        <div v-else-if="!resignation.attorneyReviewedDate">
          <p>
            Thank you for trusting us with your resignation. Your resignation has not been reviewed by us yet, but please be assured that
            we are working on it as quickly as possible. We process resignations on a first-come, first-serve basis.
          </p>
          <p>This process usually takes 2-3 days, and we will notify you by email once it has been completed.</p>
        </div>

        <div v-else-if="resignation.attorneyReviewedDate">
          <p>Your resignation has been reviewed by us and we will soon send it to Kirton McConkie.</p>
          <p>This is done via automated process, but we don't send them all at once, and we don't send any on
            weekends. This is so that your resignation is less likely to get lost / forgotten by the receiving party.
          </p>
        </div>

        <ul>
          <li>
            You <b>created</b> your resignation on <b>{{ resignation.createdDate | moment('LLL') }}</b>
          </li>
          <li v-if="resignation.userApprovedResignationDate">
            You <b>approved</b> your resignation on <b>{{ resignation.userApprovedResignationDate | moment('LLL') }}</b>
          </li>
          <li v-if="resignation.attorneyReviewedDate">
            We <b>reviewed</b> your resignation on <b>{{ resignation.attorneyReviewedDate | moment('LLL') }}</b>
          </li>

          <li v-if="resignation.submittedToMembershipDate">
            We first <b>submitted</b> your resignation to Kirton McConkie on <b>{{ resignation.submittedToMembershipDate | moment('LLL') }}</b>
          </li>

          <li v-if="resignation.confirmationReceivedDate">
            We <b>received</b> confirmation of your resignation on <b>{{ resignation.confirmationReceivedDate | moment('LLL') }}</b>
          </li>
        </ul>

        <b-link @click="downloadDocuments(resignation.resignationId)">Download your resignation documents</b-link>
      </div>

      <div v-if="resignation.confirmationReceivedDate"
           class="mb-5" />

      <div class="mb-5">
        <h2>Files</h2>
        <user-file-list :allow-delete="false"
                        :documents="documents" />
      </div>

      <h2>Change or cancel</h2>
      <div v-if="resignation.submittedToMembershipDate">
        <p>You can change or cancel your resignation only if it has not yet been submitted to Kirton McConkie.</p>
      </div>
      <div v-else>
        <p>Your resignation has not been submitted to Kirton McConkie, so you may change or cancel it if you choose.</p>
      </div>

      <div class="button-row">
        <v-btn class="mr-5"
               color="primary"
               :disabled="!userCanCancelResignation"
               outlined
               @click="userWithdrawApproval">Change or Withdraw Resignation
        </v-btn>

        <v-btn class="mr-5"
               color="red"
               dark
               :disabled="!userCanCancelResignation"
               @click="deleteResignation(resignationId)">Delete Resignation
        </v-btn>
      </div>

    </div>

    <br>
    <br>
    <router-link class="mt-10"
                 :to="{name: 'resignation-list'}">
      <font-awesome-icon class="mr-1"
                         :icon="['fas', 'arrow-left']" />
      Back to Resignation List</router-link>

    <br>
    <br>

  </b-container>
</template>

<script>
import { ResignationApi } from '@/api/ResignationApi';
import resignationMixins from '@/mixins/resignationMixins.ts';
import UserFileList from '@/components/UserFileList';
import handleError from '@/errorHandler';
import { ResignationDocumentsApi } from '@/api/ResignationDocumentsApi';

export default {
  name: 'ResignationPending',
  components: { UserFileList },
  mixins: [resignationMixins],
  data() {
    return {
      // data coming from mixin

      downloadInProgress: false,
    };
  },
  async mounted() {
    this.parseRouteParams();

    if (this.resignationId) {
      this.getResignation(this.resignationId);
      await this.getFilesForResignation(this.resignationId);
    }
  },
  methods: {

    async userWithdrawApproval() {

      const confirmWithdraw = await this.$swal({
        title: 'Withdraw Resignation',
        text: 'All data submitted, including files uploaded, will be saved. You can submit your resignation again at any time.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Withdraw Resignation',
        cancelButtonText: 'Don\'t withdraw',
      });

      if (confirmWithdraw.value === true) {
        try {
          await ResignationApi.userWithdrawApproval(this.resignationId);
          this.$router.push({ name: 'resignation-list' });
        } catch (e) {
          handleError(e);
          console.error(e);
        }
      }
    },
    async downloadConfirmationCertificate() {

      this.downloadInProgress = true;

      try {

        const { data } = await ResignationDocumentsApi.downloadConfirmation(this.resignationId);

        let decoded = atob(data.blobData);
        decoded = this.stringToBytes(decoded);

        const blob = new Blob([decoded], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName);
        document.body.appendChild(link);
        link.click();

        this.resignation.documentsDownloadedDate = new Date();
      } catch (e) {
        console.error(e);
        handleError(e, 'Download failed');
      } finally {
        this.downloadInProgress = false;
      }
    },
    async deleteResignation(resignationId) {

      const confirmDelete = await this.$swal({
        title: 'Delete Resignation',
        text: 'All data submitted, including files uploaded, will be deleted. This cannot be undone.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#dc3544',
        confirmButtonText: 'Delete Resignation',
        cancelButtonText: 'Don\'t delete',
      });

      if (confirmDelete.value === true) {
        try {
          await ResignationApi.deleteResignation(resignationId);

          this.$swal({
            title: 'Success',
            text: 'The resignation has been deleted',
            icon: 'success',
          });

          this.$router.push({ name: 'resignation-list' });

        } catch (e) {
          handleError(e);
          console.error(e);
        }
      }
    },
  },
  computed: {
    userCanCancelResignation() {
      return !this.resignation.submittedToMembershipDate;
    },
  },
};
</script>

<style scoped>

</style>
