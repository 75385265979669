import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      maxEmailAttachmentMb: state => state.frontEndSettings.maxEmailAttachmentMb,
      maxSingleAttachmentMb: state => state.frontEndSettings.maxSingleAttachmentMb
    })
  },
};
