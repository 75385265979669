<template>
  <div>
    <b-table empty-text="No files have been uploaded"
             :fields="documentFields"
             :items="documents"
             outlined
             show-empty>

      <template v-if="allowDownload"
                v-slot:cell(t_download)="data">
        <b-link @click="downloadUserDocument(data.item.resignationFileId)">
          <font-awesome-icon icon="download" />
        </b-link>
      </template>

      <template v-slot:cell(t_created)="data">
        {{ data.item.createdDate | moment('lll') }}
      </template>

      <template v-if="allowDelete"
                v-slot:cell(t_delete)="data">
        <b-link @click="deleteDocument(data.item.resignationFileId)">
          <font-awesome-icon icon="trash-alt" />
        </b-link>
      </template>

      <!-- A custom footer -->
      <template #custom-foot="data">
        <b-tr>
          <b-td />

          <b-td>
            <div class="text-right text-bold">
              Total Size
            </div>
          </b-td>
          <b-td>
            <span class="text-bold">
              {{ calculateTotalSize(data.items) | formatSize }}
            </span>
          </b-td>

          <b-td />

          <b-td />
        </b-tr>

      </template>

    </b-table>
  </div>
</template>

<script>
import { UserDocumentApi } from '@/api/UserDocumentsApi';
import handleError from '@/errorHandler';
import frontEndSettingsStoreMixin from '@/mixins/frontEndSettingsStoreMixin';

export default {
  name: 'UserFileList',
  mixins: [frontEndSettingsStoreMixin],
  props: {
    documents: {
      type: Array,
      required: true
    },
    allowDownload: {
      type: Boolean,
      required: false,
      default: false
    },
    allowDelete: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      documentFields: [
        {
          key: 't_download',
          label: ''
        },
        {
          key: 'fileName',
          label: 'File Name'
        },
        {
          key: 'fileSizeDescription',
          label: 'Size'
        },
        {
          key: 't_created',
          label: 'Uploaded'
        },
        {
          key: 't_delete',
          label: ''
        }
      ]
    };
  },
  methods: {
    async downloadUserDocument(resignationFileId) {

      try {

        const { data } = await UserDocumentApi.getUserFile(resignationFileId);

        let decoded = atob(data.blobData);
        decoded = this.stringToBytes(decoded);

        let blob = new Blob([decoded], {
          type: 'application/pdf'
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);
        handleError(e, 'Download failed');
      }
    },

    async deleteDocument(resignationFileId) {
      try {

        const confirmDelete = await this.$swal({
          title: 'Delete Document?',
          text: 'Are you sure you want to delete this document?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel'
        });

        if (confirmDelete.value === true) {
          await UserDocumentApi.deleteFile(resignationFileId);
          this.$toast.success('File Deleted');
          this.$emit('document-deleted', resignationFileId);
        }

      } catch (e) {
        handleError(e);
      }

    },

    calculateTotalSize(items) {
      return this._.sumBy(items, i => i.fileSize);
    },

    isTooLarge(items) {
      const size = this._.sumBy(items, i => i.fileSize);
      return size > this.maxEmailAttachmentMb * 1024 * 1024;
    }
  }
};
</script>

<style scoped>

</style>
